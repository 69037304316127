import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  private db: IDBDatabase;
  readonly dbName = "High5";
  readonly storeName = "High5Store";

  constructor() {}

  // --- triggers when database is closed
  onDBClosed() {
    this.db = null;
  }

  // --- open database
  public async openDB(): Promise<void> {
    let openDBPromise = new Promise<IDBDatabase>((resolve, reject) => {
      const request = window.indexedDB.open(this.dbName);

      request.onerror = (event: any) => {
        console.error(`Database error: ${event.target.errorCode}`);
        return reject();
      };

      request.onupgradeneeded = (event: any) => {
        let db: IDBDatabase = event.target.result;

        // --- create object store
        db.createObjectStore(this.storeName, { keyPath: "id" });
      };

      request.onsuccess = (event: any) => {
        let db = event.target.result;
        return resolve(db);
      };
    });

    // --- wait for db to open
    this.db = await openDBPromise;

    // --- listen if database is closed
    this.db.addEventListener("close", this.onDBClosed.bind(this));
  }

  // --- set item in object store
  public async setItem(id: string, value: any): Promise<any> {
    // --- open database
    if (!this.db) await this.openDB();

    const transaction = this.db.transaction([this.storeName], "readwrite");
    const objectStore = transaction.objectStore(this.storeName);

    const request = objectStore.put({ id, value });
    let resultPromise = new Promise((resolve, reject) => {
      request.addEventListener("success", resolve);
      request.addEventListener("error", reject);
    });

    return resultPromise;
  }

  // --- delete item from object store
  public async deleteItem(id: string): Promise<any> {
    // --- open database
    if (!this.db) await this.openDB();

    const transaction = this.db.transaction([this.storeName], "readwrite");
    const objectStore = transaction.objectStore(this.storeName);

    const request = objectStore.delete(id);

    let resultPromise = new Promise((resolve, reject) => {
      request.addEventListener("success", resolve);
      request.addEventListener("error", reject);
    });

    return resultPromise;
  }

  // --- get item from object store
  public async getItem(id: string): Promise<any> {
    // --- open database
    if (!this.db) await this.openDB();

    const transaction = this.db.transaction([this.storeName], "readonly");
    const objectStore = transaction.objectStore(this.storeName);

    const request = objectStore.get(id);

    let resultPromise = new Promise((resolve, reject) => {
      request.addEventListener("success", resolve);
      request.addEventListener("error", reject);
    });

    // --- wait for result
    await resultPromise;

    return request.result;
  }
}
