import { forwardRef, Inject, Injectable } from "@angular/core";
import lodash from "lodash";
import { ApiHelperService, CloudFnNames } from "../apiHelperService/api-helper.service"; 
import { AccessRole, CustomTokenReqType, IndexedDBKeys, LocalStorageKeys } from "../../constants/enums"; 
import { UserService } from "../user/user.service"; 
import { CommonServiceService } from "../common-service.service"; 
import { AuthDependency } from "../../interface/types";
import { Router } from "@angular/router";
import { getAuth, sendPasswordResetEmail, signInWithCustomToken, signOut } from "firebase/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { lastValueFrom, take } from "rxjs";
import { EventService } from "../event/event.service";
import { environment } from "../../../../environments/environment.stage";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "../storage/local-storage.service";
import { IndexedDBService } from "../indexDB/indexed-db.service";
import { IndividualApiService } from "../individuals/individual.service";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { EncryptionService } from "../encryption/encryption.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    @Inject(forwardRef(() => ApiHelperService)) public apiHelperService,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun: CommonServiceService,
    @Inject(forwardRef(() => UserService)) public user: UserService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private eventService: EventService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private indexedDBService: IndexedDBService,
    private db : AngularFireDatabase,
    private encryptionService: EncryptionService
  ) {}
  isAuthStateReady = false;

  // --- bulk remove users by UID (eg. bulk delete inds)
  async bulkRemoveUsers(userUIDs: string[], authDependencies?: AuthDependency[]) {
    if (!userUIDs || lodash.isEmpty(userUIDs))
      throw new Error("Params missing!");

    let reqBody = { userUIDs, authDependencies };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.bulkRemoveUsers,
      reqBody
    );
    return lodash.get(response, "result.data");
  }

  // --- bulk create auth users (eg. import)
  async bulkCreateAuthUsers(orgID: string, indIDs: string[], token?: string) {
    if (!orgID || !indIDs || lodash.isEmpty(indIDs))
      throw new Error("Params missing!");

    let reqBody = { orgID, indIDs };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.bulkCreateAuthUsers,
      reqBody,
      token
    );
    return lodash.get(response, "result.data");
  }

  // --- bulk update auth users (eg. import)
  async bulkUpdateAuthUsers(orgID: string, indIDs: string[], token?: string) {
    if (!orgID || !indIDs || lodash.isEmpty(indIDs))
      throw new Error("Params missing!");

    let reqBody = { orgID, indIDs };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.bulkUpdateAuthUsers,
      reqBody,
      token
    );
    return lodash.get(response, "result.data");
  }

  async getCurrentUserIdToken() {
    try {
      let token = await getAuth().currentUser.getIdToken();
      return token;
    } catch (err) {
      return null;
    }
  }

  async getIdTokenUsingOrgId(orgID: string) {
    if (!orgID) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, type: CustomTokenReqType.ORG };
    let [response, err] = await this.commonFun.executePromise(this.apiHelperService.postToCloudFn(
      CloudFnNames.getIdTokenUsingId,
      reqBody
    ));

    if(err) throw new Error(this.commonFun.prepareErrorMessage(err));

    return lodash.get(response, "result.data");
  }

  // --- send password reset email
  sendPWResetEmail(email: string) {
    return sendPasswordResetEmail(getAuth(), email, {
      url: this.commonFun.getCurrentURLRootPath(),
    });
  }

  // --- get current user auth state
  getAuthState() {
    return lastValueFrom(this.afAuth.authState.pipe(take(1)));
  }

  // --- sign anonymous user
  signInAnonymously() {
    return this.afAuth.signInAnonymously();
  }

  // --- check if authenticated user is logged in currently
  private async isUserLoggedIn() {
    let authState = await this.getAuthState();
    if (!authState || authState.isAnonymous) return false;
    return true;
  }

  // --- check if the given user is logged in currently
  async isGivenUserLoggedIn(orgID: string, indID: string) {
    if (!orgID || !indID) return false;

    let isLoggedIn = await this.isUserLoggedIn();
    if (!isLoggedIn) return false;

    let claims = await this.getUserCustomClaim();
    let claimsOID = lodash.get(claims, "OID");
    let claimsIID = lodash.get(claims, "IID");
    return orgID == claimsOID && indID == claimsIID;
  }

  // --- get current user data (eg. orgID, indID)
  async getCurrentUserData() {
    let [claims, _] = await this.commonFun.executePromise(
      this.getUserCustomClaim()
    );
    let orgID = lodash.get(claims, "OID");
    let indID = lodash.get(claims, "IID");
    return { orgID, indID };
  }

  // --- triggers when user gets logged in
  onUserLoggedIn(orgID: string, indID: string) {
    // --- increase activity count for dashboard
    if (orgID) {
      this.http
        .post(
          `${environment.firebaseFunctionsBaseUrl}/incrementActivityCount`,
          {
            orgID: orgID,
            type: "ppLogin"
          }
        )
        .toPromise();
    }
  }

  async logout(preventNavigation?: boolean) {
    this.localStorageService.removeItem(
      LocalStorageKeys.isPWAInstallationDetectedBefore
    ); // clear PWA installation data
    this.localStorageService.removeItem("isShowVisitorMsg")
    this.commonFun.executePromise(
      this.indexedDBService.deleteItem(IndexedDBKeys.CachedID)
    ); // clear cached ID
    this.localStorageService.removeItem(LocalStorageKeys.H5Trace); // clear high5 trace
    await this.afAuth.signOut();
    if (!preventNavigation)
      await this.router.navigate(["/auth"]);
  }

  // --- force refresh ID token of current user
  async refreshIDToken() {
    let currentUser = await this.afAuth.currentUser;
    return currentUser.getIdToken(true);
  }

  // get current user custom claim
  async getUserCustomClaim() {
    let claims = null;

    let currentUser = await this.afAuth.currentUser;
    if (currentUser && currentUser.getIdTokenResult()) {
      claims = await currentUser.getIdTokenResult();
      claims = lodash.get(claims, "claims");
    }

    return claims;
  }

  // --- get user custom token
  /**
   * get user custom token
   * @param orgID organization ID
   * @param indID individual ID
   */
  async getUserCustomToken(
    orgID: string,
    indID: string,
    authDependencies?: AuthDependency[]
  ) {
    if (!orgID || !indID) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, indID, authDependencies };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getCustomToken,
      reqBody
    );
    return lodash.get(response, "result.data");
  }

  // --- get user ID token
  async getUserIDToken(
    orgID: string,
    indID: string,
    authDependencies?: AuthDependency[]
  ) {
    if (!orgID || !indID) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, indID, authDependencies };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getIdTokenUsingId,
      reqBody
    );
    return lodash.get(response, "result.data");
  }

  isValidCustomToken(token: string) {
    return lodash.split(token, ".").length == 3;
  }

  // -- wait for post process of ind creation to be completed (eg. firebase auth user) login process
  waitForIndCreationPostPrcs(orgID: string, indID: string) {
    let waitForPostPrcsPromise = new Promise(async resolve => {
      let hash = this.encryptionService.sha256CryptoJs(`${orgID}${indID}`);
      let sub = this.db
        .object(`postIndProcessDone/${hash}/postIndPrcsDoneAt`)
        .valueChanges()
        .subscribe(timestamp => {
          if (timestamp) {
            if (sub && !sub.closed) sub.unsubscribe();
            return resolve(true);
          }
        });
    });
    let timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        return reject(new Error("Post login process timed out!"));
      }, 15000);
    });
    return Promise.race([waitForPostPrcsPromise, timeoutPromise]);
  }

  async isUserAllowedToSetPW(orgID: string, indID: string): Promise<boolean> {
    let claimData: any = await this.getUserCustomClaim();

    if (
      !claimData ||
      !claimData.GLC ||
      claimData.GLC.OID != orgID ||
      claimData.GLC.IID != indID ||
      !lodash.has(claimData.GLC, "QC")
    )
      return false;

    let questionCodes = lodash
      .chain(claimData.GLC.QC)
      .split(",")
      .filter(qc => !lodash.isNil(qc) && qc != "")
      .value();
    return lodash.every(
      questionCodes,
      qc => lodash.get(claimData.GLC, qc) == true
    );
  }

  // --- helper method to sign in user (with pre/post login processes)
  async signIn(
    method: "emailPass" | "customToken",
    services: { individualApi: IndividualApiService },
    inputs: {
      pwOrToken: string;
      isAutoLogin?: boolean;
      orgID?: string;
      indID?: string;
    }
  ) {
    if (!inputs.isAutoLogin) inputs.isAutoLogin = false;
    // --- read currently logged in anonymous user id (useful to delete later on)
    let beforeSignInAuthState = await this.getAuthState();
    let anonymousUid = "";
    if (beforeSignInAuthState && beforeSignInAuthState.isAnonymous)
      anonymousUid = beforeSignInAuthState.uid;

    // --- input validation
    if (method == "emailPass" && (!inputs.orgID || !inputs.indID))
      throw new Error("Params missing to signin!");

    if (method == "emailPass") {
      // --- prepare credentials to login
      let email = await services.individualApi.getIndEmail(
        inputs.orgID,
        inputs.indID,
        inputs.pwOrToken,
        inputs.isAutoLogin
      );
      let credentials = {
        email: email,
        password: inputs.pwOrToken
      };

      // --- sign in with email/password
      await this.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      );
    } else if (method == "customToken") {
      await this.signInWithCustomToken(inputs.pwOrToken);
    }

    // --- publish event with new auth state
    let aftrSignInAuthState = await this.getAuthState();
    this.eventService.publish("user:authStateReady", aftrSignInAuthState);

    // --- trigger post-logged in process
    this.onUserLoggedIn(inputs.orgID, inputs.indID);

    // --- remove anonymous user
    if (anonymousUid)
      await this.commonFun.executePromise(this.removeAnnUser(anonymousUid));

    return true;
  }

  // Sign in with email and password
  private signInWithEmailAndPassword(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  private signInWithCustomToken(customToken: string) {
    return this.afAuth.signInWithCustomToken(customToken);
  }

  // --- remove annonymous user
  async removeAnnUser(uid: string) {
    let [, removeUserError] = await this.commonFun.executePromise(
      this.apiHelperService.postToCloudFn(CloudFnNames.removeAnnUser, {
        uid
      })
    );
    return removeUserError ? false : true;
  }

  // --- wait for auth state to get ready
  async waitForAuthStateToGetReady() {
    let random = Math.floor(Math.random() * 1000);
    this.commonFun.appendLog(
      `Waiting for auth state to get ready! ${random}`
    );
    let waitForAuthStatePromise = new Promise(resolve => {
      if (this.isAuthStateReady) return resolve(true);

      this.eventService.subscribe("user:authStateReady", () => {
        this.commonFun.appendLog(`Auth state ready! ${random}`);
        return resolve(true);
      });
    });
    let timeoutJob;
    let timeoutPromise = new Promise((_, reject) => {
      timeoutJob = setTimeout(() => {
        return reject(new Error("Auth state timed out!"));
      }, 30 * 1000);
    });

    let [res, err] = await this.commonFun.executePromise(
      Promise.race([waitForAuthStatePromise, timeoutPromise])
    );
    if(timeoutJob) clearTimeout(timeoutJob)
    
    if (err) {
      this.commonFun.appendLog(
        `${this.commonFun.prepareErrorMessage(err)} ${random}`
      );
      this.commonFun.reportLogs();
      throw err;
    }
    return res;
  }

  // --- get challenges for selected ind
  async getChallenges(orgID: string, indID: string) {
    let challengesData = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getChallengesForInd,
      {
        orgID,
        indID
      }
    );
    return lodash.get(challengesData, "result.data");
  }

  singInWithCustomToken(customToken: string, preventRefresh: boolean = false) {
    return new Promise<any>(async (resolve, reject) => {
      signOut(getAuth())
        .then(async () => {
          // Sign-out successful.
          // --- sign in user using custom token
          let [signInRes, signInError] = await this.commonFun.executePromise(
            signInWithCustomToken(getAuth(), customToken)
          );
          console.log('signInRes: ', signInRes);

          if (signInError) {
            reject(signInError);
          }
          if (!preventRefresh) this.user.getCurrentDomainPath();
          resolve(true);
        })
        .catch((error: any) => {
          console.log('error: ', error);
          this.router.navigate(['/auth']);
          // An error happened.
        });
    });
  }

}
